<footer class="footer">
    <div class="container-fluid">
        <nav class="pull-left">
          <ul>

            <li>
              <a href="https://smartcitytelecom.com/legal/privacy-policy/" target="_blank">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="https://smartcitytelecom.com/legal/terms-of-use/" target="_blank">
                Terms of Use
              </a>
            </li>
            <li>
              <a href="https://support.smartcitymetro.com/" target="_blank">
                Support
              </a>
            </li>
          </ul>
        </nav>
        <div class="copyright pull-right">
          &copy; Copyright {{test | date: 'yyyy'}} <a href="https://www.smartcitytelecom.com" target="_blank"> Smart City Telecom LLC</a>
        </div>
    </div>
</footer>
