import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService, ApiResponse } from '../../api.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  AccountDetailQueryResult,
  BillHistoryEntity,
  BroadBandFactsQueryResult,
  BroadBandServicesQueryResult,
  UpdateEBillingPreferenceResponse,
  UpdateMarketingPreferenceResponse
} from './pages/account-detail/account-detail.component';
import { AccountSummaryQueryResult } from './pages/account-overview/account-overview.component';
import { ActionListQueryResults, AccountNoticesQueryResult, CycleSearchAccountQueryResult } from '../../core/layout/account-selector/account-selector.component';
import { DmcaListResult, DmcaAcknowledgeResponse } from './pages/dmca/dmca.component';
import 'rxjs/Rx';
import { AccountDashboardQueryResult } from './pages/dashboard/dashboard.component';
import { LogService } from '../../core/services/log.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends ApiService {
  constructor(httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, protected logService: LogService) {
    super(httpClient, _baseUrl, logService);
  }
  DownloadStatementPdf(data): Observable<any> {
    this.logService.debug("DownloadStatementPdf", data);
    return this.httpClient.post(
      this.baseUrl + 'api/statement/pdf',
      JSON.stringify(data),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: "blob"
      }
    )
      .pipe(
        catchError(this.errorHandl));
  }

  DownloadDMCALetter(data): Observable<any> {
    this.logService.debug("DownloadDMCALetter", data);
    return this.httpClient.post(
      this.baseUrl + 'api/dmca/file',
      JSON.stringify(data),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        responseType: "blob"
      }
    )
      .pipe(
        catchError(this.errorHandl));
  }

  // POST
  GetBroadBandFacts(data): Observable<ApiResponse<BroadBandServicesQueryResult>>{
    this.logService.debug("GetBroadBandServices", data);
    return this.httpClient.post<ApiResponse<BroadBandServicesQueryResult>>(this.baseUrl + 'broadband-facts/GetBroadBandServices/', {CustomerId: data}, this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }
  GetUsageData(data): Observable<BillHistoryEntity[]>{
    this.logService.debug("GetUsageData", data);
    return this.httpClient.post<BillHistoryEntity[]>(this.baseUrl + 'api/observium/GetBillHistoryByCustomerId/', {CustomerId: data}, this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }
  UpdateEBilling(data): Observable<ApiResponse<UpdateEBillingPreferenceResponse>> {
    this.logService.debug("UpdateEBilling", data);
    return this.httpClient.post<ApiResponse<UpdateEBillingPreferenceResponse>>(this.baseUrl + 'api/account/ebilling/update', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  UpdateMarketing(data): Observable<ApiResponse<UpdateMarketingPreferenceResponse>> {
    this.logService.debug("UpdateMarketing", data);
    return this.httpClient.post<ApiResponse<UpdateMarketingPreferenceResponse>>(this.baseUrl + 'api/account/marketing/update', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  GetAccountDetails(data): Observable<ApiResponse<AccountDetailQueryResult>> {
    this.logService.debug("GetAccountDetails", data);
    return this.httpClient.post<ApiResponse<AccountDetailQueryResult>>(this.baseUrl + 'api/account/details', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  GetAccountSummary(data): Observable<ApiResponse<AccountSummaryQueryResult>> {
    this.logService.debug("GetAccountSummary", data);
    return this.httpClient.post<ApiResponse<AccountSummaryQueryResult>>(this.baseUrl + 'api/account/summary', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  GetAccountList(data): Observable<ApiResponse<ActionListQueryResults>> {
    this.logService.debug("GetAccountList", data);
    return this.httpClient.post<ApiResponse<ActionListQueryResults>>(this.baseUrl + 'api/account/list', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  GetFullName(data: string): Observable<ApiResponse<string>> {
    this.logService.debug("GetFullName", data);
    return this.httpClient.post<ApiResponse<string>>(this.baseUrl + 'api/account/fullname/' + data, null, this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  AcknowledgeViolation(data): Observable<ApiResponse<DmcaAcknowledgeResponse>> {
    this.logService.debug("AcknowledgeViolation", data);
    return this.httpClient.post<ApiResponse<DmcaAcknowledgeResponse>>(this.baseUrl + 'api/dmca/acknowledge', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  GetDMCAList(data): Observable<ApiResponse<DmcaListResult>> {
    this.logService.debug("GetDMCAList", data);
    return this.httpClient.post<ApiResponse<DmcaListResult>>(this.baseUrl + 'api/dmca/list', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  GetAccountDashboard(data): Observable<ApiResponse<AccountDashboardQueryResult>> {
    this.logService.debug("GetAccountDashboard", data);
    return this.httpClient.post<ApiResponse<AccountDashboardQueryResult>>(this.baseUrl + 'api/account/dashboard', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  GetNotices(data): Observable<ApiResponse<AccountNoticesQueryResult>> {
    this.logService.debug("GetNotices", data);
    return this.httpClient.post<ApiResponse<AccountNoticesQueryResult>>(this.baseUrl + 'api/account/notices', JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      )
  }

  GetCycleSearch(data): Observable<ApiResponse<CycleSearchAccountQueryResult>> {
    this.logService.debug("GetCycleSearch", data);
    return this.httpClient.post<ApiResponse<CycleSearchAccountQueryResult>>(this.baseUrl + "api/account/cyclesearch", JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.errorHandl)
      );
  }
}
