import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService, ApiResponse } from '../../api.service';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  PayNowInfoQueryResult,
  PayNowCommandRequest,
  PayNowCommandResult,
  CancelAutoPayResult,
  CancelAutoPayRequest,
  PayNowCallbackCommandResult,
  AutoPayCallbackCommandResult
} from './pages/paynow/paynow.component';
import { AutoPayInfoQuery, AutoPayInfoResult, AutoPayCommandResult } from './pages/autopayinfo/autopayinfo.component';
import { LogService } from '../../core/services/log.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends ApiService {
  constructor(httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, protected logService: LogService) {
    super(httpClient, _baseUrl, logService);
  }

  // GET - Pay Now info
  GetPayNowInfo(data): Observable<ApiResponse<PayNowInfoQueryResult>> {
    this.logService.debug("GetPayNowInfo", data);
    return this.httpClient.post<ApiResponse<PayNowInfoQueryResult>>(this.baseUrl + 'payment/paynowinfo',
      JSON.stringify(data),
      this.httpOptions)
      .pipe(catchError(this.errorHandl)
      )
  }

  // GET - Auto Pay Info
  GetAutoPayInfo(data): Observable<ApiResponse<AutoPayInfoResult>> {
    this.logService.debug("GetAutoPayInfo", data);
    return this.httpClient.post<ApiResponse<AutoPayInfoResult>>(this.baseUrl + 'payment/autopayinfo',
      JSON.stringify(data),
      this.httpOptions)
      .pipe(catchError(this.errorHandl)
      )
  }

  // POST - PayNow
  PostPayNow(data): Observable<ApiResponse<PayNowCommandResult>> {
    this.logService.debug("PostPayNow", data);
    console.log(JSON.stringify(data));
    return this.httpClient.post<ApiResponse<PayNowCommandResult>>(this.baseUrl + 'payment/paynow',
      JSON.stringify(data),
      this.httpOptions)
      .pipe(catchError(this.errorHandl)
      )
  }

  // POST - AutoPay
  PostAutoPay(data): Observable<ApiResponse<AutoPayCommandResult>> {
    this.logService.debug("PostAutoPay", data);
    return this.httpClient.post<ApiResponse<AutoPayCommandResult>>(this.baseUrl + 'payment/autopay',
      JSON.stringify(data),
      this.httpOptions)
      .pipe(catchError(this.errorHandl)
    );
  }

  // POST - CancelAutopay
  CancelAutoPay(data): Observable<ApiResponse<CancelAutoPayResult>> {
    this.logService.debug("CancelAutoPay", data);
    return this.httpClient.post<ApiResponse<CancelAutoPayResult>>(this.baseUrl + 'payment/cancelautopay',
      JSON.stringify(data),
      this.httpOptions)
      .pipe(catchError(this.errorHandl)
      );
  }

  // POST - PayNowCallback
  PostPayNowCallback(data): Observable<ApiResponse<PayNowCallbackCommandResult>> {
    this.logService.debug("PostNowCallback", data);
    return this.httpClient.post<ApiResponse<PayNowCallbackCommandResult>>(this.baseUrl + 'payment/paynowcallback',
      JSON.stringify(data),
      this.httpOptions)
      .pipe(catchError(this.errorHandl)
      )
  }

  // POST - AutoPayCallback
  PostAutoPayCallback(data): Observable<ApiResponse<AutoPayCallbackCommandResult>> {
    this.logService.debug("PostAutoPayCallback", data);
    return this.httpClient.post<ApiResponse<AutoPayCallbackCommandResult>>(this.baseUrl + 'payment/autopaycallback',
      JSON.stringify(data),
      this.httpOptions)
      .pipe(catchError(this.errorHandl)
      )
  }
}
