import { Component, Inject, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ActionResult } from '../../../../api.service';
import { TitleService } from '../../../../core/services/title.service';
import { AccountService } from '../../account-service';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../../../payment/payment.service';
import _resourcedata from '../../../../../assets/resources/resource.json';
import { LogService } from '../../../../core/services/log.service';
import {BillHistoryEntity} from "../account-detail/account-detail.component";
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public accountDashboard: AccountDashboardQueryResult;

  private apiService: AccountService;
  private titleService: TitleService;
  private toastrService: ToastrService;
  private logService: LogService;
  protected resource: object;

  public balanceChartData: any;
  public balanceChartOptions: any;
  public balanceChartColors: [];
  public balanceChartLegend = false;
  public balanceChartType = 'line';
  public balanceChartPercentage: string;
  public balanceChartPercentageExplanation: string;

  public usageCardBillMonth: string;
  public usageCardPercentage: string;
  public usageCardPercentageExplanation: string;
  public usageCardBillAllowed: string;
  public usageCardTraficTotal: string;
  private cdr: ChangeDetectorRef // Inject ChangeDetectorRef

  // @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  //Usage Data Settings
  public usageData: any = null;
  public usageOptions: any = null;
  public showCard: boolean = true;
  constructor(_apiService: AccountService, _paymentService: PaymentService, _titleService: TitleService, _toastrService: ToastrService, private _logService: LogService) {
    this.apiService = _apiService;
    this.titleService = _titleService;
    this.toastrService = _toastrService;
    this.logService = _logService;
    this.titleService.setTitle("Account Dashboard");
    this.resource = _resourcedata.Dashboard;
  }

  ngOnInit() {
    let customerId = localStorage.getItem("AccountKey");
    if (customerId != null) {

      this.apiService.GetAccountList({}).subscribe(listResult => {
        var matchingAccount = listResult.Result.Accounts.find(x => x.AccountKey == customerId);
        if (!matchingAccount && !listResult.Result.CycleAccess) {
          customerId = listResult.Result.Accounts[0].AccountKey;
        }
        const accountDashboardPost: AccountDashboardPost = { CustomerId: customerId };
        this.apiService.GetAccountDashboard(accountDashboardPost).subscribe(res => {
          console.log("Location 5");
          console.log(JSON.stringify(res));
          console.log("set account number: " + res.Result.AccountNumber);
          localStorage.setItem("AccountNumber", res.Result.AccountNumber);
          console.log("set account address: " + res.Result.Address);
          localStorage.setItem("AccountAddress", res.Result.Address);
          console.log("set account type: " + res.Result.AccountType);
          localStorage.setItem("AccountType", res.Result.AccountType);
          console.log("set account key: " + res.Result.AccountKey);
          localStorage.setItem("AccountKey", res.Result.AccountKey);
          console.log("set accountDashboard to result");
          this.printAccountInStorage("Dashboard");
          this.accountDashboard = res.Result;
          console.log("populate chart");
          this.populateChart();
          console.log("popualte usage data");
          this.populateUsageData(res.Result.AccountNumber);
          console.log("log service");
          this.logService.debug("Get Account Dashboard", accountDashboardPost);
        }, error => this.logService.error(error));
      }, error => this.logService.error(error));
    }
    else {
      this.logService.debug("Get Account Dashboard", "Account Number is null.");
    }
  }


  /**
   * Prints the account details stored in localStorage.
   *
   * @param {string} prefix - The prefix to be added to each log message.
   */
  printAccountInStorage(prefix: string) {
    console.warn(prefix + ": AccountNumber: " + localStorage.getItem("AccountNumber"));
    console.warn(prefix + ": AccountAddress: " + localStorage.getItem("AccountAddress"));
    console.warn(prefix + ": AccountType: " + localStorage.getItem("AccountType"));
    console.warn(prefix + ": AccountKey: " + localStorage.getItem("AccountKey"));
  }

  ngAfterViewInit() {
    this.populateChart();
  }
  calculateStepSize(min: number, max: number): number {
    const range = max - min;
    // Adjust the number of intervals to control the number of ticks
    const numberOfIntervals = 5;
    let stepSize = range / numberOfIntervals;

    // Round up to the nearest 0.05 or appropriate value
    stepSize = Math.ceil(stepSize * 20) / 20; // Adjust multiplier for precision

    return stepSize;
  }
  public populateChart() {
    if (!this.accountDashboard || !this.accountDashboard.BalanceChart) {
      return;
    }
    this._logService.debug("populate chart");
    const data = this.accountDashboard.BalanceChart.map(a => a.Amount);
    const labels = this.accountDashboard.BalanceChart.map(a => a.Month);

    this.balanceChartData = {
      labels: labels,
      datasets: [
        {
          data: data,
          label: "",
          borderColor: "#084897",
          fill: false,
          tension: 0.4, //Adjust this value to control the curve of the line
          pointBackgroundColor: "#084897", //Point color - Filled in
          pointRadius: 4 // adjust the size of the point
        }
      ]
    };
    const minAmount = Math.min(...data);
    const maxAmount = Math.max(...data);
    const stepSize = this.calculateStepSize(minAmount, maxAmount);

    this.balanceChartOptions = {
      responsive: true,
      scales: {
        y: {
          beginAtZero: false,
          ticks: {
            // The amount values on the y axis
            color: 'rgba(0,0,0,0.4)',
            callback: function(value, index, values) {
              return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            stepSize: stepSize
          },
          grid: {
            color: 'transparent', // Hide y-axis grid lines
            drawBorder: false, // Hide the y-axis border line
            borderColor: 'transparent' // Hide any remaining y-axis border
          },
          border: {
            color: 'transparent' // Ensure no border line is visible
          }
        },
        x: {
          ticks: {
            color: "#9f9f9f" // The date values on x axis
          },
          grid: {
            color: 'transparent', // Hide x-axis grid lines
            drawBorder: false, // Hide the x-axis border line
            borderColor: 'transparent' // Hide any remaining x-axis border
          },
          border: {
            color: 'transparent' // Ensure no border line is visible
          }
        }
      },
      plugins: {
        legend: {
          display: false // Hide the legend
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              const value = context.parsed.y;
              return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          }
        }
      },
      interaction: {
        intersect: false
      }
    };

    var fMonth = this.accountDashboard.BalanceChart[0].Amount;
    var lMonth = this.accountDashboard.BalanceChart[this.accountDashboard.BalanceChart.length - 1].Amount;

    if (fMonth == 0) {
      this.balanceChartPercentage = "+100%";
      this.balanceChartPercentageExplanation = "Your monthly statement has increased +100% since " + this.accountDashboard.BalanceChart[0].Month + ".";
    }
    else if (fMonth > lMonth) {
      var percentDecrease = (fMonth - lMonth) / fMonth * 100;
      this.balanceChartPercentage = "-" + percentDecrease.toFixed(2) + "%";
      this.balanceChartPercentageExplanation = "Your monthly statement has decreased " + percentDecrease.toFixed(2).toString() + "% since " + this.accountDashboard.BalanceChart[0].Month + ".";
    }
    else if (lMonth > fMonth) {
      var percentIncrease = (lMonth * 100 / fMonth) - 100;
      this.balanceChartPercentage = "+" + percentIncrease.toFixed(2) + "%";
      this.balanceChartPercentageExplanation = "Your monthly statement has increased " + percentIncrease.toFixed(2).toString() + "% since " + this.accountDashboard.BalanceChart[0].Month + ".";
    }
    else {
      this.balanceChartPercentage = "+0%";
      this.balanceChartPercentageExplanation = "Your monthly statement has remained the same since " + this.accountDashboard.BalanceChart[0].Month + ".";
    }
  }

  public populateUsageData(customerId: string) {
    this.apiService.GetUsageData(customerId).subscribe(
      (res: BillHistoryEntity[]) => {
        if (res && res.length > 0) {
          this.prepareChartData(res);
        } else {
          this.toggleCardDisplay();
          console.log("No usage data found.");
        }
      },
      error => {
        console.error("API error:", error);
      }
    );
  }

  private prepareChartData(sortedData: BillHistoryEntity[]): void {
    const labels: string[] = [];
    const downloadedData: number[] = [];
    const uploadedData: number[] = [];
    const totalData: number[] = [];

    sortedData.forEach(item => {
      if (!item.BillDateTo) {
        // Ensure that item.billDateFrom is a valid Date
        console.warn("Skipping entry due to missing or invalid billDateTo:", item);
        return; // Skip invalid entries
      }
      //Create new date in case there's an issue with ORM for date column
      const date = new Date(item.BillDateTo.toString());
      const monthKey = this.getMonthYearString(this.addMonth(date));
      // Push to labels and datasets
      labels.push(monthKey);
      //Data comes into the observium router from customer, so data needs to be flipped downloaded = Traf out
      downloadedData.push(this.bytesToGigabytes(item.TrafOut));
      uploadedData.push(this.bytesToGigabytes(item.TrafIn));
      totalData.push(this.bytesToGigabytes(item.TrafTotal));
    });

    var billPercent = sortedData[0].BillPercent;
    this.usageCardBillMonth = labels[0];
    this.usageCardPercentage =  + billPercent + "%";
    this.usageCardPercentageExplanation = "Your monthly Data Usage is " + billPercent + "%";

    this.usageCardBillAllowed = this.bytesToGigabytes(sortedData[0].BillAllowed).toString();
    this.usageCardTraficTotal = this.bytesToGigabytes(sortedData[0].TrafTotal).toString();
    this.usageData = {
      labels: labels,
      datasets: [
        {
          label: 'Downloaded',
          backgroundColor: '#0366d6',
          borderColor: '#0366d6',
          pointBackgroundColor: '#0366d6',
          fill: false,
          data: downloadedData
        },
        {
          label: 'Uploaded',
          backgroundColor: '#E60000',
          borderColor: '#E60000',
          pointBackgroundColor: '#E60000',
          fill: false,
          data: uploadedData
        }
      ]
    };

    this.usageOptions = {
      responsive: true,
      scales: {
        y: {
          beginAtZero: false,
          ticks: {
            color: 'rgba(0,0,0,0.4)',
            callback: function (value, index, values) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " GB";
            }
          },
          stacked: true,
          grid: {
            color: 'transparent',
            drawBorder: false,
            borderColor: 'transparent'
          }
        },
        x: {
          ticks: {
            color: "#9f9f9f"
          },
          stacked: true,
          grid: {
            color: 'transparent',
            drawBorder: false,
            borderColor: 'transparent'
          }
        }
      },
      plugins: {
        legend: {
          display: true
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const value = context.parsed.y;
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " GB";
            }
          }
        }
      },
      interaction: {
        intersect: false
      }
    };

    console.log("Usage Data for Chart:", this.usageData);
    console.log("Chart Options:", this.usageOptions);
  }

  private addMonth(date: Date): Date {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 1);

    // Handle end-of-month boundary cases
    if (newDate.getMonth() > (date.getMonth() + 1) % 12) {
      newDate.setDate(0); // Go to the last day of the previous month
    }
    return newDate;
  }
  private getMonthYearString(date: Date): string {
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  }

  private bytesToGigabytes(bytes: number): number {
    const gigabytes = bytes / 1073741824; // 1024^3
    return bytes === 0 ? 0 : Math.max(1, Math.round(gigabytes));
  }
  toggleCardDisplay() {
    this.showCard = !this.showCard; // This is just an example to toggle the display
  }
  public hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }
}

export interface AccountDashboardPost {
  CustomerId: string
}

export interface AccountDashboardQueryResult {
  AccountNumber: string;
  CurrentBalance: number;
  DmcaViolationTotal: number;
  DmcaViolationPendingAcknowledgement: number;
  EnrolledEBilling: boolean;
  EnrolledAutoPay: boolean;
  LastLogin: Date;
  RecentActivity: AccountDashboardRecentActivityItem[];
  BalanceChart: AccountDashboardBalanceChartItem[];
  Address: string;
  AccountType: string;
  AccountKey: string;
}

export interface AccountDashboardBalanceChartItem {
  Month: string;
  Amount: number;
}

export interface AccountDashboardRecentActivityItem {
  Date: Date;
  Description: string;
  Amount: number;
}
